<template>
	<div class="patentSearch">
        <div class="search-box">
            <div class="box-div1">查专利</div>
            <div class="box-div2"></div>
            <div class="box-div3">
                <input type="text" v-model="keyword" @keyup.enter="goto">
                <img :src="require('/static/images/patentfind.png')" @click="goto" alt="">
            </div>
            <div class="box-div4">
                <div>热门搜索：</div>
                <div v-for="(v,k) in list" :key="k">
                    <div @click="to(v.name)">{{v.name}}</div>
                </div>
            </div>
        </div>
	</div>
   
</template>
<script>

export default {
  name: '',
  data(){
    return {
	 list:[
        {name:'人工智能'},
        {name:'激光'},
        {name:'光伏'},
        {name:'芯片'},
        {name:'液晶显示'},
        {name:'环保设备'},
        {name:'图像识别'},
        {name:'齿轮'},
        {name:'区块链'},
        {name:'纳米'},
     ],
     keyword:''
    }
  },
 
  components: {

  },
  watch:{
    
    
  },
  methods: {
	to(val){
        this.$router.push({
         path:'/searchDetail',
         query:{
            word:val
         }
      })
    },
	goto(){
        if(this.keyword != ""){
            this.$router.push({
         path:'/searchDetail',
         query:{
            word:this.keyword
         }
      })
        }
    }
    
  },
   mounted() {
      
    },
    
}
</script>

<style scoped>
	.patentSearch{
        width: 100vw;
        height: 100vh;
        background: url('../../../static/images/patentSear.png') 100% no-repeat;
        background-size: cover;
        position: relative;
    }
    .search-box{
        position: absolute;
        width: 1200px;
        height: 180px;
        top:40%;
        left: 50%;
        transform: translate(-50%,-50%);
    }
    .box-div1{
        width: 86px;
        height: 40px;
        margin-left: 37px;
        background: #4096FF;
        text-align: center;
        line-height: 40px;
        color: #FFFFFF;
        font-size: 20px;
    }
    .box-div2{
        position: absolute;
        width: 0;
        height: 0;
        top: 36px;
        left:75px;
        border-style: solid;
        border-width: 4px;
        border-color: transparent transparent #4096FF #4096FF;
        transform: rotate(-45deg);
        z-index: 100;
    }
    .box-div3>input{
        width: 1085px;
        height: 64px;
        margin-top: 15px;
        padding: 0 80px 0 35px;
        background: #FFFFFF;
        outline: none;
        border: none;
        font-size: 24px;
        color: #333333;
    }
     .box-div3>img{
        position: absolute;
        top: 63px;
        cursor: pointer;
        right:32px;
     }
     .box-div4{
        height: 64px;
        font-size: 20px;
        background: rgba(102,102,102,0.7);
        color: #FFFFFF;
     }
     .box-div4>div{
        float: left;
        margin-top: 18px;
        margin-right: 32px;
        cursor: pointer;
     }
     .box-div4>div:nth-of-type(1){
        margin-left: 32px;
        cursor: default;
     }
</style>


